import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import { Panels } from '../components/Panels';
import { graphql, Link } from 'gatsby';
import { Hero } from '../components/Hero';
import { Seo } from '../components/Seo';
import { Fragment } from 'react';

const AboutUs: Page = ({ data: { aboutItems } }) => {
    return (
        <Fragment>
            <Seo
                title="About Us | Clermont Kids Dentistry & Orthodontics, P.A."
                description='At Clermont Kids Dentistry & Orthodontics, we strive to assist families in raising children have incredibly fun dental patients. "Because every TOOTH matters!'
            />
            <main>
                <Hero type="about us" title="About Us">
                    <StaticImage
                        alt="outside of Clermont Kids office"
                        className="hero-image"
                        src="../images/clermont-building.jpeg"
                    />
                </Hero>
                <Panels>
                    {aboutItems.edges.map(service => {
                        return (
                            <Link
                                key={service.node.frontmatter.title}
                                to={service.node.frontmatter.slug}
                            >
                                <GatsbyImage
                                    alt={service.node.frontmatter.thumbnail.name}
                                    image={
                                        service.node.frontmatter.thumbnail.childImageSharp
                                            .gatsbyImageData
                                    }
                                />
                                <p>{service.node.frontmatter.title}</p>
                            </Link>
                        );
                    })}
                </Panels>
            </main>
        </Fragment>
    );
};

export const query = graphql`
    query {
        aboutItems: allMdx(
            sort: { order: ASC, fields: frontmatter___order }
            filter: { frontmatter: { type: { eq: "about us" } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        type
                        images {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                        title
                        slug
                        thumbnail {
                            childImageSharp {
                                gatsbyImageData(quality: 100, placeholder: NONE)
                            }
                            name
                        }
                    }
                }
            }
        }
    }
`;

export default AboutUs;
